import React from 'react'

const RewardCatalogSlideUp = ({ rewardPointProp }) => {
  return (
    <section class="w-full flex justify-center items-center px-1 py-10">
        <div class="max-w-7xl lg:px-5 xl:px-0 flex flex-col lg:items-start">
          <p className="text-center p-0 m-0 mx-auto">{rewardPointProp.sectionHeading}</p>
          <h2 class="homepage-heading py-3 text-center w-full mx-auto max-w-6xl">
            {rewardPointProp?.sectionHeadingFirst}<br/><span className={rewardPointProp?.headingClass}>{rewardPointProp?.sectionHeadingSecond}</span>
          </h2>
          <div class="lg:max-w-2xl mx-auto">
            <p class={(rewardPointProp?.paragraph?.length) ? "text-gray-500 py-1 text-center" : "hidden"}>
              {rewardPointProp.paragraph}
            </p>
          </div>
          <div className={`grid grid-cols-1 md:grid-cols-2 xl:grid-cols-${rewardPointProp.slideDetails.length} gap-5 mt-3 xl:mt-8 p-5 md:p-6 mx-auto lg:items-center rounded-xl overflow-hidden`}>
            {
                (rewardPointProp.slideDetails)?.map(prop => {
                    return(
                        <div className="rounded-xl p-2 md:py-4 lg:p-1 bg-white shadow cursor-pointer rewardsCatalogTabs">
                            <div className="relative overflow-hidden rounded-xl">
                                <picture>
                                    <source
                                    loading="lazy"
                                    srcset={`https://res.cloudinary.com/vantagecircle/image/upload/w_300/q_auto/gatsbycms/uploads/${prop.img}`}
                                    type="image/webp"
                                    alt="company-celebrations"
                                    width="300"
                                    />
                                    <img
                                    loading="lazy"
                                    src={`https://res.cloudinary.com/vantagecircle/image/upload/w_280/q_auto/gatsbycms/uploads/${prop.img}`}
                                    alt="company-celebrations"
                                    width="300"
                                    className="rounded-lg mx-auto"
                                    />
                                </picture>
                                <div className="flex items-cente absolute bottom-0 left-0 right-0 frostedBGBlurx2 shadow rounded-xl slideUpFrostedOverlay">
                                    <p className= "text-gray-500 text-sm leading-relaxed xl:leading-7">
                                    {prop.description}
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-center py-8 items-center">
                                <p className="text-md text-center text-gray-500 m-0 p-0">{prop.heading}</p>
                            </div>
                        </div>
                    )
                })
            }
          </div>
        </div>
      </section>
  )
}

export default RewardCatalogSlideUp