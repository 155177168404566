
import React,{useState, useEffect} from 'react'
import ReactDOM from "react-dom"
import useImagePreloader from '../hooks/useImagePreloader'

const Flipper = ({ contents }) => {
    const [drop, setDrop] = useState(0)
    const [mobilelink, setMobilelink] = useState(false)
    const [imageWidth, setimageWidth] = useState("700")

    let desktopImageArray = contents.map(content => {
        return(
            content.desktopImage
        )
    })

    let mobileImageArray = contents.map(content => {
        return(
            content.mobileImage
        )
    })

    const desktopImageLoaded = useImagePreloader(desktopImageArray)
    const mobileImageLoaded = useImagePreloader(mobileImageArray)

    
    const upArrow = '&or;'
    const downArrow ='&and;'
    const toggleDrop = (i) => {
        setDrop(i)
    }

    const checkDeviceWidth = () => {
        if(window.innerWidth !== undefined){ 
            if(window.innerWidth < 769){
                setMobilelink(true)
                setimageWidth("568")
            }
        }
    }

    // const renderImageDiv = (arg) => {
    //     return(
    //         <div className='rounded-xl flex justify-center lg:justify-end items-center'>
    //             {/* <img className='rounded-xl transition duration-300 transform scale-100' src = {`https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/07/${arg}`} width={imageWidth} height="608" /> */}
    //             <picture>
    //                 <source className='rounded-xl transition duration-300 transform scale-100' srcset={`https://res.cloudinary.com/vantagecircle/image/upload/w_800/q_auto/gatsbycms/uploads/${arg}`} media="(min-width:650px)" type="image/webp" alt="RR-Buyers-Guide-Thumbnail.png" width={imageWidth} height="608" />
    //                 <source className='rounded-xl transition duration-300 transform scale-100' srcset={`https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/gatsbycms/uploads/${arg}`} media="(min-width:250px)" type="image/webp" alt="RR-Buyers-Guide-Thumbnail.png" width={imageWidth} height="608" />
    //                 <img className='rounded-xl transition duration-300 transform scale-100' src={`https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/${arg}`} alt="RR-Buyers-Guide-Thumbnail.png" width={imageWidth} height="608" />
    //             </picture>
    //         </div>
    //     )
    // }

    useEffect(() => {
        checkDeviceWidth()
    }, [])
    

  return (
    <div className='lg:grid grid-cols-2 justify-center items-center w-11/12 xl:max-w-7xl mx-auto bg-white pt-6 pb-5 px-5 xl:p-10 xl:py-12 rounded-xl shadow'>
    
        <div className='flex flex-col justify-center items-start'>
            <h2 className='subHeading text-center lg:text-left mx-auto lg:mx-0 pb-5 lg:pb-2'><span className='text-orange'>More reasons </span> to choose <br/>Vantage Rewards</h2>
            <div className='lg:w-11/12'>
                
                {  (mobileImageLoaded === true) && 
                    contents?.map((content, index) => {
                        return(
                            <div className={drop === index ? `mt-3 bg-gray-newGrayDark border border-gray-light p-5 ${content.imageMobilePaddingBottom} lg:pb-5 lg:w-10/12 rounded-md px-3 lg:pl-5 w-full` : 'bg-gray-newGrayDark border border-gray-light lg:w-10/12 rounded-md mt-3 px-5 py-2 lg:py-3 lg:pl-5 w-full'}>
                                
                                <h2 className={content.tabName?.length ? `pl-1 py-2 text-center heroTagStyle uppercase` : `hidden`}>
                                    <span className="font-bold">{content.tabName}</span>
                                </h2>

                                <h3 className='xl:text-md cursor-pointer flex text-left'  onClick={() => toggleDrop(index)}>
                                    <span dangerouslySetInnerHTML={{ __html: content.heading }} /> 
                                    <div className='arrow flex place-content-center pl-1'>
                                        {drop === index ? <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/07/Group-3-Copy-5-2.webp' width="12" /> : <img src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/07/Group-3-Copy-5.webp' width="12" />}
                                    </div>
                                </h3>

                                <div className={drop === index ? `${content.tabHeightMobile} md:h-600 xl:h-16 lg:h-20 overflow-hidden transition-all duration-300 grid items-center` : 'h-0 overflow-hidden transition-all duration-300'}>
                                    <p className='text-sm pt-2 pb-4 xl:py-0 m-0 text-left'>{contents[drop].paragraph}</p>
                                    <a href={content.knowMoreLink} className={content.knowMoreLink?.length ? 'text-orange mb-5' : 'hidden'}>Know more</a>
                                     <div className='rounded-xl flex lg:hidden justify-center lg:justify-end items-center'>
                                            {/* <picture>
                                                <source className='rounded-xl transition duration-300 transform scale-100' loading="lazy" srcset={`https://res.cloudinary.com/vantagecircle/image/upload/${contents[drop].mobileImageQuality}/q_auto/gatsbycms/uploads/${contents[drop].image}`} media="(max-width:649px)" type="image/webp" alt="RR-Buyers-Guide-Thumbnail.png" width="568" height="608" />
                                                <img className='rounded-xl transition duration-300 transform scale-100' loading="lazy" src={`https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/gatsbycms/uploads/${contents[drop].image}`} alt="RR-Buyers-Guide-Thumbnail.png" width={imageWidth} height="608" />
                                            </picture>                                         */}
                                            <img className='rounded-xl transition duration-300 transform scale-100' loading="lazy" src={contents[drop].mobileImage} alt="RR-Buyers-Guide-Thumbnail.png" width={imageWidth} height="608" />

                                    </div>
                                </div>
                                
                            </div>
                        )
                    })
                }
            </div>
        </div>
        <div className='rounded-xl hidden lg:flex justify-center lg:justify-end items-center'>
            {
                (desktopImageLoaded === true) &&
                    <img className='rounded-xl transition duration-300 transform scale-100' loading="lazy" src={contents[drop].desktopImage} alt="RR-Buyers-Guide-Thumbnail.png" width="700" height="608" />
            }
        </div>
    </div>
  )
}

const renderFlipper=()=>{
    if(typeof window !== "undefined"){ 
        let id = document.querySelector("#flipperID")
        if (id != null){
            ReactDOM.render(
                <Flipper />,
                id
            )
        }
    }
}

setTimeout(renderFlipper,100)

export default Flipper