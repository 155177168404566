import React from 'react'
import { useEffect, useState } from "react"
import {
    CarouselProvider,
    Slider,
    Slide,
    ButtonBack,
    ButtonNext,
    DotGroup,
  } from "pure-react-carousel"
import ArrowIcon from './ArrowIcon'

const RnRGCCCarousel = ({ RnRSlideDetails }) => {

    const [mobileScreen, setMobileScreen] = useState(false)

    useEffect(() => {
      if(typeof window !== undefined){
        window.innerWidth <= 1023 ? setMobileScreen(true) : setMobileScreen(false)
      }
    }, [])
    

  return (
    <CarouselProvider
        className="max-w-5xl px-2 relative mx-auto max-h-1500 h-auto xl:h-auto"
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={RnRSlideDetails?.length}
        visibleSlides= {mobileScreen ? 1 : 3}
        isPlaying={true}
        interval={4000}
        infinite= {true}
      >
            <Slider className="z-0 grid items-start lg:items-center min-h-250px md:h-72 lg:h-auto lg::min-h-96">
                {
                    RnRSlideDetails?.map((slide, index) => {
                        return(
                            <Slide index={index}>
                                <div className={`grid bg-white mx-1 px-4 py-0 border border-gray-150 rounded-md min-h-250px ${slide.slideShadow}`}>
                                    <div className='flex justify-center'>
                                        <img src={`https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/${slide.slideImage}`} className={`w-28 h-28 transform ${slide.ImageSize} object-contain`} />
                                    </div>
                                    <p className='text-center mx-auto'>{slide.slideDescription}</p>
                                </div>
                            </Slide>
                        )
                    })
                }
            </Slider>

            <div className='pt-7 lg:pt-0 flex justify-center items-center'>
                <ButtonBack className={`border border-newOrange ${RnRSlideDetails[0]?.slideShadow} mx-1 xl:mx-0 mt-0 eb-carousel-btn xl:absolute left-2 transform xl:-translate-x-8 transform-y-10 rotate-180 bg-white p-3 md:p-4 rounded-full`} style={{ bottom: "55%" }}>
                    <ArrowIcon />
                </ButtonBack>
                <ButtonNext className={`border border-newOrange ${RnRSlideDetails[0]?.slideShadow} mx-1 xl:mx-0 xl:mt-0 eb-carousel-btn xl:absolute right-2 transform xl:translate-x-8 transform-y-10 bg-white p-3 md:p-4 rounded-full`} style={{ bottom: "55%" }}>
                    <ArrowIcon />
                </ButtonNext>
            </div>
    </CarouselProvider>
  )
}

export default RnRGCCCarousel