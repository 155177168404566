import React from 'react'
import ReactDOM from "react-dom";
import Trans from './Trans';

const awardsIcons = [
  "2024/05/Gartner-badge.webp",
  "2024/05/Brandon-hall-gold.webp",
  "2024/05/Brandon-hall-silver.webp",
  "2024/05/Bakers-dozen-gold.webp",
  "2024/07/g2-6.png",
  "2024/07/g2-7.png",
  "2024/07/g2-9.png",
  "2024/07/g2-11.png",
  "2024/07/g2-12.png"
]

const badgeIcons = [
  "2024/07/g2-6.png",
  "2024/07/g2-7.png",
  "2024/07/g2-9.png",
  "2024/07/g2-11.png",
  "2024/07/g2-12.png"
]

const AwardBadges = () => {
  return (
    <section class="w-full xl:max-w-7xl mx-auto flex flex-col place-items-center z-0 px-2 pt-10 xl:py-16">
        <div class="flex flex-col justify-center items-center bg-white">
            <h2 class="text-gray-500 homepage-section-heading text-center mb-3 text-center max-w-4xl">
               <Trans>Winner of top global awards in Employee Recognition and Engagement</Trans>
            </h2>
              <div className='w-full md:w-10/12 xl:w-full mx-auto grid grid-cols-3 md:grid-cols-4 gap-10 md:gap-16 xl:gap-10 place-content-center my-5'>
                {
                  awardsIcons.map((awardIcon, index) => {
                    return(
                      <div className={index > 3 ? 'flex place-content-center xl:hidden' : 'flex place-content-center'}>
                        <picture className='my-auto'>
                          <source
                            className="z-10 relative"
                            media="(min-width: 1025px)"
                            srcset={`https://res.cloudinary.com/vantagecircle/image/upload/w_200/q_auto/gatsbycms/uploads/${awardIcon}`}
                            type="image/webp"
                            alt="Vantage Rewards"
                            width="140"
                          />
                          <source
                            className="z-10 relative"
                            srcset={`https://res.cloudinary.com/vantagecircle/image/upload/w_100/q_auto/gatsbycms/uploads/${awardIcon}`}
                            type="image/webp"
                            alt="Vantage Rewards"
                            width="100"
                          />
                          <img
                            className="z-10 relative  mx-auto lg:mx-0"
                            src={`https://res.cloudinary.com/vantagecircle/image/upload/w_80/q_auto/gatsbycms/uploads/2024/05/${awardIcon}`}
                            alt="Vantage Rewards"
                            width="50"
                          />
                        </picture>
                      </div>
                    )
                  })
                }
              </div>
              <div className='w-full md:w-10/12 xl:w-full mx-auto grid grid-cols-3 md:grid-cols-5 gap-10 md:gap-16 xl:gap-10 place-content-center mt-5 mb-5'>
              {
                badgeIcons.map(awardIcon => {
                  return(
                    <div className='xl:flex hidden place-content-center'>
                      <picture className='my-auto'>
                        <source
                          className="z-10 relative"
                          media="(min-width: 1025px)"
                          srcset={`https://res.cloudinary.com/vantagecircle/image/upload/w_200/q_auto/gatsbycms/uploads/${awardIcon}`}
                          type="image/webp"
                          alt="Vantage Rewards"
                          width="140"
                        />
                        <source
                          className="z-10 relative"
                          srcset={`https://res.cloudinary.com/vantagecircle/image/upload/w_100/q_auto/gatsbycms/uploads/${awardIcon}`}
                          type="image/webp"
                          alt="Vantage Rewards"
                          width="100"
                        />
                        <img
                          className="z-10 relative  mx-auto lg:mx-0"
                          src={`https://res.cloudinary.com/vantagecircle/image/upload/w_80/q_auto/gatsbycms/uploads/2024/05/${awardIcon}`}
                          alt="Vantage Rewards"
                          width="50"
                        />
                      </picture>
                    </div>
                  )
                })
              }
              </div>
        </div>
    </section>
  )
}

const renderAwardBadges = () => {
    if(typeof window !== "undefined"){ 
        let id = document.querySelector("#BadgesSection")
        if (id != null){
            ReactDOM.render(
                <AwardBadges />,
                id
            )
        }
    }
}

setTimeout(renderAwardBadges, 100)

export default AwardBadges