import { useState, useEffect } from 'react';

const useImagePreloader = (srcArray) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const loadImage = (src) => new Promise((resolve, reject) => {
        const img = new Image()
        img.src = src
        img.onload = () => resolve()
        img.onerror = () => reject()
      })

    Promise.all(srcArray.map(loadImage)).then(() => {
      if (isMounted) setLoaded(true)
    })

    return () => {
      isMounted = false
    }
  }, [srcArray])

  return loaded
}

export default useImagePreloader