import React from 'react';

const ArrowIcon = () => (
  <svg width="20px" height="20px" viewBox="0 0 7 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Homepage" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="VC_Homepage_(Laptop)" transform="translate(-272, -3405)" fill="#FF6D05" fillRule="nonzero">
        <g id="Go-to-Arrow-Copy-3" transform="translate(272.3077, 3405)">
          <path d="M3.2307948,9.23076923 C3.091295,9.23076923 2.95165883,9.17813489 2.84515898,9.07300082 L-2.60937872,3.68841348 C-2.82251478,3.47801073 -2.82251478,3.13730097 -2.60937872,2.92703283 C-2.39624266,2.7167647 -2.05110679,2.71663008 -1.83810709,2.92703283 L3.2307948,7.93092985 L8.29969669,2.92703283 C8.51283275,2.71663008 8.85796862,2.71663008 9.07096832,2.92703283 C9.28396801,3.13743558 9.28410438,3.47814535 9.07096832,3.68841348 L3.61643061,9.07300082 C3.50993076,9.17813489 3.3702946,9.23076923 3.2307948,9.23076923 Z" id="Down_Arrow_3_" transform="translate(3.2308, 6) rotate(-90) translate(-3.2308, -6)"></path>
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowIcon;
