import React,{useState, useEffect, useRef} from 'react'
import Layout from '../components/layout'
import { useLanguages } from '../hooks/useLanguages'
import HubspotForm from "react-hubspot-form"
import Helmet from "react-helmet"
import RnRGCCCarousel from '../components/RnRGCCCarousel'
import RewardCatalogSlideUp from '../components/RewardCatalogSlideUp'
import AwardBadges from '../components/AwardBadges'
import Flipper from '../components/Flipper'
import loadable from '@loadable/component'

const resolutions = {
    desktop: {
        height: "680",
        width: "1200"
    },
    smallDesktop: {
        height: "600",
        width: "800"
    },
    tablet: {
        height: "320",
        width: "540"
    },
    mobile: {
        height: "260",
        width: "330"
    }
  }

const RewardsRecognitionPlatformV2 = ({ location }) => {
    
  const [currLanguage] = useLanguages()
  const videoContainerRef = useRef(null)
  const [videoLoaded, setVideoLoaded] = useState(false)
  const [videoResoultion, setvideoResoultion] = useState(resolutions["desktop"])


useEffect(() => {

  if(typeof window !== undefined){
    //   window.innerWidth < 767 ? setvideoResoultion(resolutions["mobile"]) : setvideoResoultion(resolutions["desktop"])
    if(window.innerWidth < 410){
        setvideoResoultion(resolutions["mobile"])
    }  
    else if (window.innerWidth < 769) {
        setvideoResoultion(resolutions["tablet"])
    }
    else if (window.innerWidth < 1025) {
        setvideoResoultion(resolutions["smallDesktop"])
    }
    else{
        setvideoResoultion(resolutions["desktop"])
    }
  }

  const handleIntersection = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setVideoLoaded(true);
      }
  }
  
  const observer = new IntersectionObserver(handleIntersection, {threshold: 0.5})

  if (videoContainerRef.current) {
      observer.observe(videoContainerRef.current);
  }

  return () => {
      if (videoContainerRef.current) {
          observer.unobserve(videoContainerRef.current);
        }

  }
}, [])

  const rewardPointProp = {
    sectionHeading: "",
    sectionHeadingFirst: 'Enhance the employee reward experience with our',
    sectionHeadingSecond: "Global Rewards Catalog",
    headingClass: "text-orange",
    slideDetails : [{
      img:
        "/2024/02/Rewards-Catalog-Experience.png",
      parentClass:
        "relative overflow-hidden cursor-pointer rewardCatalogCardHeight",
      class:
        "xl:rounded-l-xl relative transform scale-105 hover:scale-110 -translate-y-64 hover:-translate-y-52 md:hover:-translate-y-2 xl:hover:-translate-y-16 md:translate-y-0 duration-300 w-full object-cover",
      heading: "EXPERIENCES",
      headingClass:
        "w-full xl:w-530 font-bold text-3xl md:text-3xl xl:text-4xl absolute left-6 top-20 md:top-16 md:left-20 md:-bottom-10 lg:left-32 xl:-left-20 xl:bottom-40 text-white transform md:-rotate-90 pointer-events-none",
      description:
        "Experiences are our experiential offerings for your employees. From cycling tours for sports enthusiasts to vineyard tours for wine connoisseurs, from relaxing and luxurious hotel stays to trekking and camping in nature's lap, reward your employees with lifetime experiences to create everlasting memories.",
    },
    {
      img:
        "/2024/02/Rewards-Catalog-Merchandise.png",
      parentClass:
        "relative overflow-hidden cursor-pointer rewardCatalogCardHeight",
      class:
        "relative transform hover:scale-110 scale-105 -translate-y-64 hover:-translate-y-52 md:hover:-translate-y-2 xl:hover:-translate-y-16 md:translate-y-0 duration-300 w-full object-cover",
      heading: "MERCHANDISE",
      headingClass:
        "w-full xl:w-530 font-bold text-3xl md:text-3xl xl:text-4xl absolute left-6 top-20 md:top-16 md:left-20 md:-bottom-10 lg:left-32 xl:-left-20 xl:bottom-40 text-white transform md:-rotate-90 pointer-events-none",
      description:
        "Merchandise offers an all-inclusive solution for point redemption, featuring a diverse range of products from top brands, delivered directly to employees. This eliminates the hassle of navigating various merchant sites ensuring a smooth experience and allowing preferred organizational vendors to be onboarded.",
    },
    {
      img:
        "/2024/02/Rewards-Catalog-Gifting.png",
      parentClass:
        "relative overflow-hidden cursor-pointer rewardCatalogCardHeight",
      class:
        "relative transform hover:scale-110 scale-105 -translate-y-64 hover:-translate-y-52 md:hover:-translate-y-2 xl:hover:-translate-y-16 md:translate-y-0 duration-300 w-full object-cover",
      heading: "GIFT CARDS",
      headingClass:
        "w-full xl:w-530 font-bold text-3xl md:text-3xl xl:text-4xl absolute left-6 top-20 md:top-16 md:left-20 md:-bottom-10 lg:left-32 xl:-left-20 xl:bottom-40 text-white transform md:-rotate-90 pointer-events-none",
      description:
        "Gift cards, a perennial favorite, offer a simple and accessible way to redeem reward points. Employees can easily exchange Vantage Points for Gift Cards, available in various denominations in 70+ countries, from various global brands across fashion, travel, entertainment, groceries, and more.",
    }
]
}

  const RnRGCCCarouselDetails = [
    {
        slideShadow: '',
        slideImage: '2023/05/Infosys.webp',
        slideDescription: 'Infosys saw a 15% surge in employee engagement led to a more productive work environment.',
        ImageSize: 'scale-110'
    },
    {
        slideShadow: '',
        slideImage: '2024/05/Sun-Pharma.png',
        slideDescription: 'Sun Pharma elevated employee engagement by 12.5% using our Rewards & Recognition platform.',
        ImageSize: 'scale-110'
    },
    {
        slideShadow: '',
        slideImage: '2023/10/11.png',
        slideDescription: 'ACG saw a 66% increase in employee recognition using our behavioural science powered R&R platform.',
        ImageSize: 'scale-125'
    },
    {
        slideShadow: '',
        slideImage: '2024/07/LT-1-1.png',
        slideDescription: 'LTTS saw a 20% annual rise in rewards redemption since 2021 after starting to use our R&R platform.',
        ImageSize: 'scale-75'
    },
    {
        slideShadow: '',
        slideImage: '2024/05/TATA-Motors.png',
        slideDescription: 'Tata Motors saw a remarkable boost in R&R platform participation, with 2X growth in employee engagement.',
        ImageSize: 'scale-150'
    },
]

const Flippercontents = [
    {
        tabName: '',
        imageMobilePaddingBottom: 'pb-0',
        mobileImageQuality: 'w_400',
        heading: 'Social Recognition &nbsp;',
        paragraph: 'Boost employee morale with social recognition and a gamified leaderboard that acknowledges achievements & drives performance.',
        image: '2024/06/Social-Recognition-min.png',
        knowMoreLink: '',
        tabHeightMobile: 'h-96',
        desktopImage:"https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2024/06/Social-Recognition-min.png",
        mobileImage: "https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/gatsbycms/uploads/2024/06/Social-Recognition-min.png"
    },
    {
        tabName: '',
        imageMobilePaddingBottom: 'pb-0',
        mobileImageQuality: 'w_400',
        heading: 'Long Service Awards &nbsp;',
        paragraph: `Make employees' work anniversaries unforgettable with an AI-powered service year book, service points and a milestone catalog.`,
        image:'2024/06/Long-Service-Awards-min.png',
        knowMoreLink: '',
        tabHeightMobile: 'h-96',
        desktopImage:"https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2024/07/VC-Paid-LSA.png",
        mobileImage: "https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/gatsbycms/uploads/2024/07/VC-Paid-LSA.png"
    },
    {
        tabName: '',
        imageMobilePaddingBottom: 'pb-0',
        mobileImageQuality: 'w_400',
        heading: 'Global Rewards Catalog &nbsp;',
        paragraph: 'Give your employees the flexibility to redeem rewards from 10M+ products, top brands, gift cards, experiences, and more.',
        image: '2024/06/VC-Landing-Page-Global-Rewards-Catalog-min.png',
        knowMoreLink: '',
        tabHeightMobile: 'h-96',
        desktopImage:"https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2024/07/VC-Paid-p2p-Recognition.png",
        mobileImage: "https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/gatsbycms/uploads/2024/07/VC-Paid-p2p-Recognition.png"
    },
    {
        tabName: '',
        imageMobilePaddingBottom: 'pb-0',
        mobileImageQuality: 'w_400',
        heading: 'Zero surcharge on gift cards &nbsp;',
        paragraph: 'Enjoy the true worth of Gift Cards at face value with zero markup.',
        image:'2024/06/Zero-surcharge-on-gift-cards-min.png',
        knowMoreLink: '',
        tabHeightMobile: 'h-96',
        desktopImage:"https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2024/07/VC-Paid-Global-Recognition.png",
        mobileImage: "https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/gatsbycms/uploads/2024/07/VC-Paid-Global-Recognition.png"
    },
    {
        tabName: '',
        imageMobilePaddingBottom: 'pb-0',
        mobileImageQuality: 'w_400',
        heading: 'Align recognition to goals &nbsp;',
        paragraph: 'Promote your organizational values with hashtags and create custom badges and awards for greater impact of your R&R program.',
        image:'2024/06/Align-Recognition-to-Goals-min.png',
        knowMoreLink: '',
        tabHeightMobile: 'h-96',
        desktopImage:"https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2024/07/VC-Paid-SOLI.png",
        mobileImage: "https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/gatsbycms/uploads/2024/07/VC-Paid-SOLI.png"
    },
    {
        tabName: '',
        imageMobilePaddingBottom: 'pb-0',
        mobileImageQuality: 'w_400',
        heading: 'Multilingual capabilities &nbsp;',
        paragraph: 'Embrace linguistic inclusion and create a sense of belonging for your global employees with our multi-lingual platform of 16+ languages.',
        image:'2024/06/Mask-group-min.png',
        knowMoreLink: '',
        tabHeightMobile: 'h-96',
        desktopImage:"https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2024/07/VC-Paid-Customer-Support.png",
        mobileImage: "https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/gatsbycms/uploads/2024/07/VC-Paid-Customer-Support.png"
    }
  ]

  return (
    <Layout location={location} pageLanguage={currLanguage}>
        <Helmet>
            <title>Boost Employee Productivity with Rewards and Recognition Program. </title>

            <meta name="description" content= "Build a workplace where recognizing employees is easy. Use our reward and recognition platform to boost engagement, productivity and the morale of your team." />
            <meta property="twitter:title" content="Boost Employee Productivity with Rewards and Recognition Program. " />
            <meta name= "robots" content= "noindex" />
                    
            <meta property="twitter:description" content="Build a workplace where recognizing employees is easy. Use our reward and recognition platform to boost engagement, productivity and the morale of your team." />
                    
            <meta property="og:type" content="website"/>
                    
            <meta property="og:url" content="https://www.vantagecircle.com/rewards-and-recognition-platform-v2/"/>

            <meta property="og:site_name" content="Vantage Circle"/>
            <meta property="og:title" content="Boost Employee Productivity with Rewards and Recognition Program." />

            <meta property="og:description" content="Build a workplace where recognizing employees is easy. Use our reward and recognition platform to boost engagement, productivity and the morale of your team." />
            <link rel="canonical" href="https://www.vantagecircle.com/rewards-and-recognition-platform-v2/"></link>
        </Helmet>

        <section className='pb-10 lg:pb-16 lg:pt-0 px-5' id ="watchVideoClass">
            <div className='lg:flex justify-around max-w-7xl mx-auto bg-indigo-100 rounded-xl px-7 py-10 lg:px-10 lg:py-16'>
                <div className='lg:w-1/2 lg:mr-0 lg:my-auto'>
                    <h2 className="text-center lg:text-left heroTagStyle-lightPurpleText pb-2">
                    REWARDS AND RECOGNITION PLATFORM
                    </h2>
                    <h2 className='homepage-div-heading text-purple-100 mt-3 xl:mt-0'>
                    Recognise and Nurture Productive Behaviours for <span className='text-orange'>Business Growth</span>
                    </h2>
                    <p className='text-purple-100 my-5 lg:my-10'>
                    We empower HR and Business leaders to identify, recognise, and reinforce 
                    productive behaviours through targeted employee rewards & recognition, 
                    data-driven insights and holistic employee wellness.
                    </p>

                    <div>
                        <h2 className='homepage-small-heading text-left text-purple-100'>Delivering simple yet effective solution globally</h2>
                        <div className='grid grid-cols-3 mt-5 mb-4 w-full lg:w-10/12'>
                            <div className='grid items-start'>
                                <h2 className='homepage-div-small-heading text-left text-orange'>10K+</h2>
                                <p className='text-left text-purple-100'>Reward Options</p>
                            </div>
                            <div className='border-l border-r grid items-start justify-center md:place-content-center'>
                                <h2 className='homepage-div-small-heading text-left text-orange'>700+</h2>
                                <p className='text-left text-purple-100'>Companies</p>
                            </div>
                            <div className='grid items-start lg:place-content-center mx-auto'>
                                <h2 className='homepage-div-small-heading text-left text-orange'>3.2M+</h2>
                                <p className='text-left text-purple-100'>Users</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className='lg:w-1/2 flex place-content-center lg:ml-5 pt-5 lg:pt-0' id="form">
                    <div className='rounded-xl bg-white py-10 px-5 lg:w-5/6 lg:ml-auto rnrForm-WhiteFields'>
                        <h2 className='homepage-small-heading text-center mx-auto mb-5'>
                            Book a personalized meeting for your unique business needs
                        </h2>
                        <div class="w-full flex justify-center items-center lato rewards team">
                                <div class="flex justify-center items-center">
                                    <HubspotForm
                                    portalId="6153052"
                                    formId="e8d9dbdc-b7b0-44da-a06b-cbfafceb5674"
                                    onSubmit={() => console.log("Submit!")}
                                    onReady={form => console.log("Form ready!")}
                                    loading={<div>Loading...</div>}
                                    />
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='w-full py-10 xl:py-16 px-5'>
            <div className='max-w-7xl mx-auto homepage-div-heading'>
                <h2 className='text-center mx-auto lg:mb-10'><span className='text-orange'>Trusted by</span> companies of all sizes, including Fortune 500</h2>
                <div className='c-logo-strip-2024'></div>
            </div>
        </section>


        <section className='py-10 xl:py-16 w-full' id="features">
            <div className='max-w-7xl mx-auto'>
                <h2 className='text-center homepage-section-heading mb-4'>
                    Why HR professionals <span className='text-orange'>choose Vantage Circle</span>
                </h2>
                <p className='text-center text-gray-500'>We help you drive values through our Rewards & Recognition platform.</p>
                <ul class="px-3 pt-6 xl:py-16 grid md:grid-cols-2 xl:grid-cols-3 gap-16 xl:gap-10 w-full mx-auto justify-center">
                    <div class="rounded-xl border border-white shadow px-6 py-10 relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" class="absolute left-5 -top-4 z-10">
                            <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                <g transform="translate(-126 -16336)">
                                    <g transform="translate(126 16336)">
                                        <rect width="46" height="46" x="0" y="0" fill="#53539C" rx="23"></rect>
                                        <g fill="#FFF" fill-rule="nonzero" transform="translate(9 13)">
                                            <path d="M25.042 15.559c-.005-2.446-2.02-4.427-4.508-4.432a4.487 4.487 0 00-3.94 2.33h-5.189a4.487 4.487 0 00-3.939-2.33c-2.488.005-4.503 1.986-4.508 4.432 0 2.45 2.025 4.441 4.508 4.441s4.508-1.991 4.508-4.441c0-.092-.018-.174-.028-.266h4.108c-.01.092-.028.174-.028.266 0 2.45 2.025 4.441 4.508 4.441s4.508-1.991 4.508-4.441zm2.278-6.68a53.344 53.344 0 00-4.434-1.019L21.45.74a.931.931 0 00-.458-.624.976.976 0 00-.784-.055 17.838 17.838 0 01-12.414 0 .916.916 0 00-.775.055.916.916 0 00-.467.624L5.114 7.86A54.432 54.432 0 00.67 8.88a.921.921 0 00-.635 1.138c.14.486.653.761 1.148.633a47.318 47.318 0 0125.632 0c.084.018.168.037.252.037a.92.92 0 00.252-1.808z"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <h3 class="pb-2">Easy peer-to-peer recognition</h3>
                        <p class="text-base m-0 text-gray-500">Foster a culture of appreciation with on-spot peer-to-peer recognition.</p>
                    </div>
                    <div class="rounded-xl border border-white shadow px-6 py-10 relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" class="absolute left-5 -top-4 z-10">
                            <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                <g transform="translate(-438 -16336)">
                                    <g transform="translate(438 16336)">
                                        <rect width="46" height="46" x="0" y="0" fill="#53539C" rx="23"></rect>
                                        <g fill="#FFF" fill-rule="nonzero" transform="translate(13 13)">
                                            <path d="M19.091 9.975a.913.913 0 01-.91-.916 9.605 9.605 0 00-2.795-6.807.921.921 0 010-1.296.903.903 0 011.286 0A11.43 11.43 0 0120 9.06a.913.913 0 01-.909.916zm-18.182 0A.913.913 0 010 9.06C0 5.998 1.182 3.12 3.328.956a.903.903 0 011.286 0 .92.92 0 010 1.296A9.603 9.603 0 001.818 9.06a.913.913 0 01-.909.916zm17.618 5.529a6.16 6.16 0 01-2.163-4.698V8.25c0-3.226-2.377-5.896-5.455-6.343v-.99A.913.913 0 0010 0c-.502 0-.91.41-.91.917v.99c-3.078.447-5.454 3.117-5.454 6.343v2.556a6.168 6.168 0 01-2.17 4.705c-.354.304-.557.75-.557 1.218A1.6 1.6 0 002.5 18.333h15a1.6 1.6 0 001.591-1.604c0-.47-.203-.913-.564-1.225zM10 22c1.646 0 3.024-1.183 3.34-2.75H6.66C6.976 20.817 8.354 22 10 22z"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <h3 class="pb-2">R&R Program Consultation & Design</h3>
                        <p class="text-base m-0 text-gray-500">Receive expert guidance tailored to your R&R programs.</p>
                    </div>
                    <div class="rounded-xl border border-white shadow px-6 py-10 relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" class="absolute left-5 -top-4 z-10">
                            <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                <g transform="translate(-752 -16336)">
                                    <g transform="translate(752 16336)">
                                        <rect width="46" height="46" x="0" y="0" fill="#53539C" rx="23"></rect>
                                        <g fill="#FFF" fill-rule="nonzero" transform="translate(13 13)">
                                            <path d="M9.413 9.411V5.838h-3.7a21.589 21.589 0 00-.35 3.573h4.05zm0-4.751V0c-1.374.344-2.687 1.92-3.43 4.66h3.43zm-5.224 5.929H0a9.971 9.971 0 00.866 3.534H4.52a23.296 23.296 0 01-.332-3.534zM0 9.41h4.188a23.19 23.19 0 01.33-3.573H.884A9.97 9.97 0 000 9.411zM10.587.005V4.66h3.42c-.281-1.026-.618-1.798-.929-2.362-.695-1.26-1.563-2.054-2.491-2.293zM9.413 10.589h-4.05c.031 1.374.162 2.544.35 3.534h3.7v-3.534zm4.692-8.862c.464.841.837 1.826 1.115 2.933h3.26a10.2 10.2 0 00-1.404-1.76A10.014 10.014 0 0013.279.503c.298.359.574.768.826 1.225zm.531 7.684a21.544 21.544 0 00-.357-3.573h-3.692v3.573h4.05zm.606 5.89c-.28 1.13-.656 2.135-1.128 2.99a7.84 7.84 0 01-.806 1.197 10.016 10.016 0 003.767-2.389 10.194 10.194 0 001.43-1.798h-3.263zM4.768 4.66c.278-1.115.652-2.106 1.118-2.95A7.84 7.84 0 016.692.512 10.016 10.016 0 002.924 2.9c-.538.54-1.008 1.13-1.404 1.76h3.248zm10.704 1.178a23.24 23.24 0 01.339 3.573H20a9.97 9.97 0 00-.884-3.573h-3.644zM5.895 18.273c-.47-.85-.845-1.85-1.124-2.972H1.496c.401.643.879 1.247 1.429 1.798a10.015 10.015 0 003.796 2.399 7.868 7.868 0 01-.826-1.225zm4.692-7.684v3.534h3.707a21.64 21.64 0 00.344-3.534h-4.051zm9.413 0h-4.188a23.242 23.242 0 01-.323 3.534h3.645c.5-1.106.795-2.3.866-3.534zM10.587 15.3V20c1.38-.345 2.7-1.934 3.441-4.7h-3.441zm-1.174 4.694v-4.694h-3.43c.283 1.046.624 1.83.94 2.401.694 1.26 1.562 2.054 2.49 2.293z"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <h3 class="pb-2">SOLI-based reward system</h3>
                        <p class="text-base m-0 text-gray-500">Reward system with fair compensation for the global workforce.</p>
                    </div>
                    <div class="rounded-xl border border-white shadow px-6 py-10 relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" class="absolute left-5 -top-4 z-10">
                            <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                <g transform="translate(-1068 -16336)">
                                    <g transform="translate(1068 16336)">
                                        <rect width="46" height="46" x="0" y="0" fill="#53539C" rx="23"></rect>
                                        <g fill="#FFF" fill-rule="nonzero" transform="translate(15 11)">
                                            <path d="M7.997 8.002C6.177 8 4.412 8.624 2.996 9.767V7a5.001 5.001 0 0110.002 0v1a1 1 0 102 0v-1a7.001 7.001 0 10-14.002 0v5.137a7.999 7.999 0 107.001-4.136zm0 14.002a6.001 6.001 0 116.001-6.001 6.008 6.008 0 01-6.001 6.001z"></path>
                                            <path d="M8 13a2.993 2.993 0 00-1 5.816V19a1 1 0 102 0v-.184A2.993 2.993 0 008 13zm0 4a1 1 0 110-2 1 1 0 010 2z"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <h3 class="pb-2">Hassle-free integration</h3>
                        <p class="text-base m-0 text-gray-500">Seamless integration with SaaS platforms to make employee recognition efficient.</p>
                    </div>
                    <div class="rounded-xl border border-white shadow px-6 py-10 relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" class="absolute left-5 -top-4 z-10">
                            <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                <g transform="translate(-1068 -16336)">
                                    <g transform="translate(1068 16336)">
                                        <rect width="46" height="46" x="0" y="0" fill="#53539C" rx="23"></rect>
                                        <g fill="#FFF" fill-rule="nonzero" transform="translate(15 11)">
                                            <path d="M7.997 8.002C6.177 8 4.412 8.624 2.996 9.767V7a5.001 5.001 0 0110.002 0v1a1 1 0 102 0v-1a7.001 7.001 0 10-14.002 0v5.137a7.999 7.999 0 107.001-4.136zm0 14.002a6.001 6.001 0 116.001-6.001 6.008 6.008 0 01-6.001 6.001z"></path>
                                            <path d="M8 13a2.993 2.993 0 00-1 5.816V19a1 1 0 102 0v-.184A2.993 2.993 0 008 13zm0 4a1 1 0 110-2 1 1 0 010 2z"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <h3 class="pb-2">On spot Global recognition</h3>
                        <p class="text-base m-0 text-gray-500">Real-time awarding with access to a global employee base.</p>
                    </div>
                    <div class="rounded-xl border border-white shadow px-6 py-10 relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" class="absolute left-5 -top-4 z-10">
                            <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                <g transform="translate(-1068 -16336)">
                                    <g transform="translate(1068 16336)">
                                        <rect width="46" height="46" x="0" y="0" fill="#53539C" rx="23"></rect>
                                        <g fill="#FFF" fill-rule="nonzero" transform="translate(15 11)">
                                            <path d="M7.997 8.002C6.177 8 4.412 8.624 2.996 9.767V7a5.001 5.001 0 0110.002 0v1a1 1 0 102 0v-1a7.001 7.001 0 10-14.002 0v5.137a7.999 7.999 0 107.001-4.136zm0 14.002a6.001 6.001 0 116.001-6.001 6.008 6.008 0 01-6.001 6.001z"></path>
                                            <path d="M8 13a2.993 2.993 0 00-1 5.816V19a1 1 0 102 0v-.184A2.993 2.993 0 008 13zm0 4a1 1 0 110-2 1 1 0 010 2z"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <h3 class="pb-2">24*7 customer support</h3>
                        <p class="text-base m-0 text-gray-500">Get answers to all your questions in real-time.</p>
                    </div>
                </ul>
            </div>
        </section>

        <section className="w-full">
            <div className="w-full py-10">
                <div className="w-11/12 xl:max-w-7xl mx-auto rounded-xl bg-gray-newGrayDark py-10 xl:pt-16 xl:pb-5">
                {/* <p className="text-center p-0 m-0">DASHBOARD & ANALYTICS</p> */}
                <h2 class="homepage-heading py-3 text-center w-full">
                    Making life<span class="text-orange"> easier</span> for HR.
                </h2>
                <p className="text-gray-500 py-3 text-center mx-auto w-11/12 lg:w-8/12">
                    Save time and effort with seamless navigation & gain a bird's-eye
                    view of the overall recognition program with real-time insights and
                    downloadable reports for informed decision-making.
                </p>
                <div className="">
                    <ul className="pl-8 pt-6 xl:pt-10 grayTick md:grid grid-cols-2 xl:grid-cols-4 gap-10 w-11/12 mx-auto justify-center">
                    <li className="flex place-content-center">
                        <p className="text-base">
                        Tailor the Recognition Program to fit your business needs with{" "}
                        <span className="font-bold">Configurations</span>
                        </p>
                    </li>
                    <li className="xl:pl-2 flex place-content-center">
                        <p className="text-base">
                        Streamline internal communication with company-wide{" "}
                        <span className="font-bold">Announcements</span>
                        </p>
                    </li>
                    <li className="flex place-content-center">
                        <p className="text-base">
                        Gain deeper visibility into key metrics with{" "}
                        <span className="font-bold">Manager Insights</span>
                        </p>
                    </li>
                    <li className="flex place-content-center">
                        <p className="text-base">
                        Optimize resource allocation for cost-effective HR decisions
                        with <span className="font-bold">Budget Management</span>
                        </p>
                    </li>
                    </ul>
                    <div
                    className="pt-6 px-4"
                    >
                    <picture>
                        <source
                        className="mx-auto my-3"
                        loading="lazy"
                        srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/Rewards-Dashboardanalytics.png"
                        media="(min-width: 768px)"
                        type="image/webp"
                        alt="vantage-rewards-social-recognition"
                        width="1500"
                        />
                        <img
                        className="mx-auto mt-5"
                        loading="lazy"
                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/Rewards-dashboard-mobile.png.webp"
                        srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/Rewards-dashboard-mobile.png.webp"
                        alt="vantage-rewards-social-recognition"
                        width="400"
                        />
                    </picture>
                    </div>
                </div>
                </div>
                <div className="w-5/6 md:w-1/3 xl:w-1/5 2xl:w-1/6 mx-auto">
                <a
                    href="#form"
                    className="vc-new-orange-btn-rounded-full text-white lato mx-auto rounded-full mt-10"
                >
                    Book a product demo
                </a>
                </div>
            </div>
        </section>

        <section className='py-10  xl:py-16' id="features">
            <Flipper contents={Flippercontents} />
        </section>

        <section className=''>
        <div className='mx-auto grid place-content-center'>                
            <div ref={videoContainerRef} className='my-10'>
                {videoLoaded ? (
                <iframe className='rounded-xl' width={videoResoultion["width"]} height={videoResoultion["height"]} src="https://www.youtube.com/embed/VPsxJWr2lH8?autoplay=1&mute=1" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerpolicy="strict-origin-when-cross-origin" 
                    allowfullscreen>
                </iframe>
                ) : (
                <picture>
                    <source className='z-10 relative mb-3 rounded-xl' srcSet="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/06/GCC-Landing-Page-Video-Thumbnail-min.png" media="(min-width: 640px)" type="image/webp" alt="Vantage Perks" width="600" />
                    <img className='z-10 relative mx-auto rounded-xl' src="https://res.cloudinary.com/vantagecircle/image/upload/w_400/q_auto/gatsbycms/uploads/2024/06/GCC-Landing-Page-Video-Thumbnail-min.png" alt="Vantage Perks" width="400" />
                </picture>)}
                </div>
            </div>
        </section>

        <section className="w-full flex flex-col place-items-center py-10 xl:py-16 px-2 xl:px-0">
            <h2 className="lg:pr-3 w-full md:w-4/6 xl:max-w-5xl mx-auto homepage-section-heading text-center px-2 pb-5">
                Unlock your employees’ full potential with <span className="text-orange">comprehensive solutions</span> for Business Growth
            </h2>
            <p className="text-gray-500 text-base md:text-lg xl:text-2xl py-3 text-center px-3 hidden">
                Our SaaS products are powerful alone but stronger together
            </p>
            <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-6 justify-center items-center max-w-7xl mx-auto my-2 xl:pt-6 px-6 xl:px-0 2xl:px-6">
                {/* Product Block 1 */}
                <div className="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
                <img decoding="async" loading="lazy" className="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-HomeRewards-3.webp" alt="Homepage-Rewards-1" width="300" height="231" />
                <h3 className="lato w-5/6 mx-auto text-center">Employee Rewards and Recognition platform</h3>
                
                </div>

                {/* Product Block 2 */}
                <div className="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
                <img decoding="async" loading="lazy" className="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-HomeVfit-3.webp" alt="Homepage-vfit-1" width="300" height="231" />
                <h3 className="lato w-5/6 mx-auto text-center">Employee Wellness <br /> platform</h3>
                
                </div>

                {/* Product Block 3 */}
                <div className="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
                <img decoding="async" loading="lazy" className="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-HomePulse-3.webp" alt="Homepage-pulse-1" width="300" height="231" />
                <h3 className="lato w-5/6 mx-auto text-center">Employee Feedback <br /> platform</h3>
                
                </div>

                {/* Product Block 4 */}
                <div className="rounded-xl transform hover:scale-105 transition duration-500 px-5 py-7 my-2 xl:my-5 shadow bg-white">
                <img decoding="async" loading="lazy" className="mx-auto pb-3" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/01/VC-HomePerks-3.webp" alt="Homepage-perks-1" width="300" height="231" />
                <h3 className="lato w-5/6 mx-auto text-center">Corporate Discounts <br /> platform</h3>
                
                </div>
            </div>
        </section>

        <section className='bg-indigo-100 w-full py-10 xl:py-16 xl:pb-0 px-5' id='successStories'>
            <div className='max-w-7xl mx-auto lg:px-5'>
                <div className='lg:flex'>
                    <div className='lg:w-7/12 lg:pl-5 lg:my-auto'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_300/q_auto/gatsbycms/uploads/2024/07/Paid-Landing-Wipro-Logo-1.png" media="(min-width: 1200px)" width="200"/>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_200/q_auto/gatsbycms/uploads/2024/07/Paid-Landing-Wipro-Logo-1.png" media="(min-width: 640px)" width="150"/>
                            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/q_auto/gatsbycms/uploads/2024/07/Paid-Landing-Wipro-Logo-1.png" alt="A description of the image." width="150"/>
                        </picture>
                        <h2 className='homepage-div-heading text-purple-100 my-7'>Wipro achieved <span className='text-orange'>Cultural Transformation</span> through innovative <span className="hero-text">R&R platform</span></h2>
                        <p className='text-purple-100'>In the last fiscal year, over 57% of Wipro's employees received recognition, equivalent to one every 1.2 minutes. Wipro saw a 97.5% increase in appreciation since 2022, leading to improved overall productivity.</p>
                    </div>
                    <div className='lg:w-6/12 lg:ml-5 lg:px-5 pt-7 lg:pt-0 flex lg:justify-end'>
                        <div className='rounded-xl border border-gray-darklight grid justify-center px-5 lg:px-12 py-8 lg:pt-8 lg:pb-0 lg:min-h-450px h-auto lg:w-9/12'>
                            <p className='italic text-purple-100 text-sm lg:text-base leading-7'>
                            At Wipro, we firmly believe that when appreciation becomes a habit, excellence becomes the norm. Our recognition program, Winners' Circle, has been instrumental in embedding recognition into our daily work life and culture. Over 57% of our associates received recognition in the last fiscal year. Considering our size, this coverage attests to the strong performance of our recognition program.
                            </p>
                            <div className='flex justify-end w-full relative lg:h-28 pt-4 lg:pt-0'>
                                <div className='flex lg:absolute -right-24 bg-indigo-100'>
                                    <div className='grid my-auto'>
                                        <h2 className='homepage-mini-heading text-purple-100 text-right m-0'>
                                        Sunita Cherian
                                        </h2>
                                        <p className='text-right text-purple-100 text-right text-base m-0'>
                                        Senior VP and Chief Culture Officer,<br/>Wipro
                                        </p>
                                    </div>                                  
                                    <div className='rounded-full border-gray-darklight border p-2 ml-3 my-auto'>
                                        <picture>
                                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_300/q_auto/gatsbycms/uploads/2024/07/Mask-group-1.png" media="(min-width: 1200px)" width="80"/>
                                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_200/q_auto/gatsbycms/uploads/2024/07/Mask-group-1.png" media="(min-width: 640px)" width="80"/>
                                            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/q_auto/gatsbycms/uploads/2024/07/Mask-group-1.png" alt="A description of the image." width="80"/>
                                        </picture>
                                    </div>
                                </div>     
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' mt-10 lg:mt-20 rnrCarousel'>
                    <RnRGCCCarousel RnRSlideDetails = {RnRGCCCarouselDetails} />
                </div>
            </div>
        </section>

        <section className='xl:pt-10'><RewardCatalogSlideUp rewardPointProp = {rewardPointProp} /></section>

        <section className="py-10 xl:pb-5 xl:pt-16 px-4" id="integrations">
            <div className="w-full grid justify-center max-w-7xl mx-auto">
                <h2 className="homepage-heading w-11/12 md:w-10/12 lg::max-w-4xl text-center pb-5 xl:pb-12 lg:tracking-wide mx-auto">
                    Seamless <span class="text-orange">integrations </span>with your existing HCM/HRIS/SSO platforms
                </h2>
            <div>
                <div className="hidden lg:grid grid-cols-3 mx-auto mt-5 max-w-5xl">
                    <p className="mx-auto text-gray-500 text-lg">Social Integrations</p>
                    <p className="mx-auto text-gray-500 text-lg">SSO Integrations</p>
                    <p className="mx-auto text-gray-500 text-lg">HRIS Integrations</p>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 mx-auto my-5 max-w-5xl">
                    <div className="lg:border-r px-5">
                        <p className="mx-auto text-gray-500 text-lg lg:hidden pb-6 text-center">Social Integrations</p>
                        <div className="grid grid-cols-3 gap-5">
                            <div className="intLogo mx-auto" id="logo1"></div>
                            <div className="intLogo mx-auto" id="logo2"></div>
                            <div className="intLogo mx-auto" id="logo3"></div>
                            <div className="intLogo mx-auto" id="logo4"></div>
                            <div className="intLogo mx-auto" id="logo5"></div>
                            <div className="intLogo mx-auto" id="logo6"></div>
                        </div>
                    </div>
                    <div className="lg:border-r px-5 pt-5 lg:pt-0 lg:pl-9">
                        <p className="mx-auto text-gray-500 lg:hidden pt-10 text-lg pb-6 text-center">SSO Integrations</p>
                        <div className="flex xl:grid justify-center gap-5 pl-5 lg:pl-0">
                            <div className="intLogo mx-auto" id="logo7"></div>
                            <div className="intLogo mx-auto" id="logo8"></div>
                        </div>
                    </div>
                    <div className="px-5 pt-5 lg:pt-0">
                        <p className="mx-auto text-gray-500 lg:hidden pt-10 text-lg pb-6 text-center">HRIS Integrations</p>
                        <div className="grid grid-cols-3 gap-5 lg:pl-9">
                            <div className="intLogo mx-auto" id="logo9"></div>
                            <div className="intLogo mx-auto" id="logo10"></div>
                            <div className="intLogo mx-auto" id="logo11"></div>
                            <div className="intLogo mx-auto" id="logo12"></div>
                            <div className="intLogo mx-auto" id="logo13"></div>
                        </div>
                    </div>
                </div>
            </div>
                <div class="max-w-7xl mx-auto grid grid-cols-1 gap-5 lg:gap-10 mt-10 mb-2 lg:mb-8">
                    <a href="#form" class="vc-new-orange-btn-rounded-full text-purple-100" rel="noopener">Explore all integrations</a>
                </div>
            </div>
        </section>

        <section class="mx-auto z-0 flex flex-col place-content-center relative overflow-hidden lg:overflow-visible py-10 xl:py-16 z-0 lg:px-3">
        <div className="w-11/12 xl:max-w-7xl bgMicrosoft mx-auto grid lg:grid-cols-2 justify-center items-center z-10 relative p-8 rounded-xl">
          <div className="w-full md:w-11/12 lg:w-full mx-auto flex flex-col text-center lg:text-left justify-center lg:items-start items-center xl:mr-3 xl:pt-12 xl:pb-20">
            <picture>
              <source
                className="z-0 mt-5 mb-2"
                loading="lazy"
                srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/microsoft-teams-logo-2-1-1.webp"
                type="image/webp"
                alt="msteams"
                width="200"
              />
              <img
                className="z-0 mt-5 mb-2"
                loading="lazy"
                src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/microsoft-teams-logo-2-1-1.png"
                alt="msteams"
                width="200"
              />
            </picture>
            <h2 className="lg:pr-3 pt-4 md:pt-5 xl:pt-3 xl:w-full text-3xl md:text-4xl xl:text-5xl text-center lg:text-left">
              Recognize while collaborating on Teams
            </h2>
            <div className="xl:flex justify-center items-center lg:justify-start w-5/6 md:w-1/2 lg:w-2/3 xl:w-1/2 cursor-pointer my-8 z-10">
              <a
                className="vc-new-orange-border-btn-rounded-full lato rounded-full mb-3 w-full xl:mb-0 xl:mr-2 text-orange"
                href="/integration/microsoft-teams/"
              >
                Know more
              </a>
            </div>
          </div>
          <div className="hidden xl:block absolute h-72 w-72 -top-0  -right-0 overflow-hidden rounded-xl">
            <div className="relative h-full w-full">
              <div className="h-80 w-80 rounded-full absolute -top-20 -right-20 z-0" style={{ background: '#cdcde4' }}></div>
            </div>
          </div>
          <div className="lg:absolute -bottom-72 md:-bottom-64 md:scale-90 lg:scale-75 transform xl:scale-100 md:right-5 lg:-bottom-24 lg:-right-20 xl:-bottom-16 xl:right-6 flex justify-center items-center h-full py-4 md:mt-0 md:pt-0 lg:ml-3">
            <picture>
              <source
                loading="lazy"
                srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/07/Rewards-MSTEams.webp"
                type="image/webp"
                alt="msteams"
                width="700"
              />
              <img
                loading="lazy"
                src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/07/Rewards-MSTEams.png"
                alt="msteams"
                width="700"
              />
            </picture>
          </div>
        </div>
      </section>

        <section className='py-6 px-5'>
            <div id='BadgesSection'></div>
        </section>

    </Layout>
  )
}

export default RewardsRecognitionPlatformV2